<template>
  <div style="position: relative;">
    <div class="search-div">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select
            v-model:value="searchForm.orgId"
            placeholder="业务部门"
            style="width: 200px"
          >
          <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id"
              @click="orgChange(item.id)">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="业务员">
          <a-select
            v-model:value="searchForm.saleName"
            :filter-option="false"
            show-search
            placeholder="请输入员工姓名,按下回车搜索"
            @search="employerValue"
            style="width: 300px;"
          >
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option
              v-for="item in employeeOptions"
              :key="item.id"
              :value="item.name"
              @click="employeeChoose(item)"
            >{{ item.name }}-{{ item.no }}-{{ item.orgName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" :loading="loading" @click="searchBtn">搜索</a-button>
            <a-button :loading="loading" @click="resetBtn">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <div class="private-intention">
      <div class="title">
        <span>私海待办提醒</span>
      </div>
      <div class="data-statistics">
        <template v-for="(item, index)  in contactList" :key="index">
          <div :class="index == 0 ? 'data-show' : 'data-show1'" >
            <div @click="openDetail(item)">
              <div class="number-title">{{ item.remindType?.label }}</div>
              <div style="margin-left: 22px;cursor: pointer" class="number-show" >{{ item.num }}</div>
              <!-- <div style="margin-left:22px">占比 {{ item.proportion }}</div> -->
            </div>
          </div>
        </template>
      </div>
    </div>

    <div style="display: flex">
      <div
        style="
          width: 400px;
          display: flex;
          flex-direction: column;
          background: #fff;
          margin-top: 10px;
          padding: 10px;
        "
      >
        <div class="title">
          <span>私海客户预警</span>
        </div>
        <div style="width: 100%" v-for="(item, index) in warningList" :key="index">
          <div style="position: relative; width: 100%; margin-top: 25px">
            <div >
              <div>{{item.remindType?.label}}</div>
              <div style="font-weight: bold; font-size: 36px; color: #344563">{{item.num}}</div>
            </div>
            <div class="img-div">
              <img :src="imgs[index]" style="width: 70px; height: 70px" />
            </div>
          </div>
          <!-- <div>即将掉进临时客户，请及时添加跟进</div> -->
          <a style="font-size: 18px;cursor: pointer" v-if="item.num > 0" @click="openDetail(item)">点击查看</a>
          <a-divider />
        </div>
      </div>

      <div class="num-profit-div">
        <div class="title">
          <span>私海客户意向等级统计</span>
        </div>
        <div class="content-div">
          <div class="statictics">
            <div style="font-size: 36px; color: #344563; font-weight: bold">{{resultData.totalNum}}</div>
            <div style="font-size: 16px; color: #6b778c">私海客户总数</div>
          </div>
          <div class="statictics">
            <div style="font-size: 36px; color: #344563; font-weight: bold">{{resultData.yesterdayNum}}</div>
            <div style="font-size: 16px; color: #6b778c">昨日新增</div>
          </div>
          <div class="statictics">
            <div style="font-size: 36px; color: #344563; font-weight: bold">{{resultData.currentMonthNum}}</div>
            <div style="font-size: 16px; color: #6b778c">本月新增</div>
          </div>
          <div class="statictics" v-for="(item, index) in intentionList" :key="index">
            <div style="font-size: 36px; color: #344563; font-weight: bold">{{item.num}}</div>
            <div style="font-size: 16px; color: #6b778c">{{item.intentionLevel?.label}}</div>
          </div>
        </div>
      </div>

      <div class="ranking-div">
        <div class="title">
          <span>客户标签标签统计</span>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div style="width: 15%" v-for="(item, index) in tagList" :key="index">
            <div class="data-num">{{item.num}}</div>
            <div class="data-label">{{item.tagType?.label}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-div" v-show="loading">
      <a-spin size="large" />
    </div>
  </div>
  <a-modal v-model:visible="detailShow" width="80%" :title="detailTitle" footer="" :getContainer="false"
           :bodyStyle="{ paddingTop: '10px' }">
      <RemindDetail ref="remindDetail" :remind="remindType" :orgId="orgId" :saleId="saleId" :customerClass="customerClass"/>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { privateKanBan } from '@/api/dataCenter/kanban'
import RemindDetail from './RemindDetail'
import { employeeList } from '@/api/crmManagement/setRules'
export default {
  components: {
    RemindDetail
  },
  setup () {
    const remindDetail = ref(null)
    const state = reactive({
      loading: false,
      flagLoading: false,
      fetching: false,
      remindType: {},
      orgId: null,
      saleId: null,
      customerClass: null,
      detailShow: false,
      detailTitle: undefined,
      searchForm: {
        orgId: null,
        saleName: ''
      },
      timer: null,
      employeeOptions: [],
      imgs: [
        require('@/assets/kanBan/W0.png'),
        require('@/assets/kanBan/W1.png'),
        require('@/assets/kanBan/W2.png')
      ],
      tagList: [],
      intentionList: [],
      warningList: [],
      contactList: [],
      listData: [],
      resultData: {
      }
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      state.loading = true
      privateKanBan({
        ...state.searchForm
      }).then(res => {
        if (res.code === 10000) {
          state.resultData = res.data
          state.contactList = res.data.toDoRemindList
          state.warningList = res.data.warningRemindList
          state.intentionList = res.data.intentionList
          state.tagList = res.data.tagList
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const orgChange = item => {
      state.searchForm.orgId = item
    }
    const handleTableChange = (page) => {
      state.pagination = page
    }
    const searchBtn = () => {
      loadData()
    }
    const resetBtn = () => {
      state.searchForm.saleId = null
      state.searchForm.saleName = undefined
      state.searchForm.orgId = null
      searchBtn()
    }

    const openDetail = (item) => {
      if (item.num > 0) {
        state.detailShow = true
        state.detailTitle = item.remindType.label
        state.remindType = item.remindType
        state.orgId = state.searchForm.orgId
        state.saleId = state.searchForm.saleId
        state.customerClass = 2
        setTimeout(() => {
          remindDetail.value.loadData(1)
        }, 10)
      }
    }
    // 员工搜索
    const employerValue = (e) => {
      if (e === '' || e === null) {
        clearTimeout(state.timer)
        state.fetching = false
      }
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        })
          .then((res) => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.flagLoading = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1000)
    }

    // 选择员工
    const employeeChoose = (item) => {
      state.searchForm.saleId = item.id
      state.searchForm.saleName = item.name + ' - ' + item.orgName
      state.employeeOptions = []
    }

    return {
      ...toRefs(state),
      remindDetail,
      searchBtn,
      orgChange,
      resetBtn,
      openDetail,
      employeeChoose,
      employerValue,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
